import {
  BRAZE_SIGNUP_SOURCE,
  SKU_NAME_KEY,
  SUBSCRIPTION_ACTIVE_KEY,
  BRAZE_CHECKOUT,
  SUBSCRIBED,
  BRAZE_BLOG,
  BRAZE_TOPIC,
  BRAZE_BLOG_CATEGORIES,
  BRAZE_BLOG_SLUGS,
  BRAZE_BLOG_TAGS,
  BRAZE_BENEFITS_ANSWERS,
  BRAZE_BENEFITS_QUIZ,
  BRAZE_MARKETING_LANDING,
  FIRST_SIGNUP_DATE,
  BRAZE_LANDING_PAGE_SLUGS,
  SIGNUP,
} from 'braze';
import updateUserSubscription from './subscription';

// Braze Web SDK must be dynamically imported due to server side support
// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#upgrading-the-sdk
export async function initializeBraze(config) {
  try {
    const { initialize, openSession, getUser } = await import('@braze/web-sdk');

    const started = initialize(config.BRAZE_WEB_KEY, {
      baseUrl: config.BRAZE_SDK_URL,
      enableLogging: config.LINGO_ENV !== 'prod',
    });

    if (!started) {
      return null;
    }

    openSession();

    return getUser();
  } catch (error) {
    throw new Error(`Braze initialization failed: ${error.message}`);
  }
}

export async function createBrazeUser(
  config,
  externalId,
  existingUser,
  updateSubscription,
  email,
  signUpSource,
) {
  try {
    if (config?.BRAZE_SDK_URL == null || config?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (email == null) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (!existingUser) {
      brazeUser.addAlias(email.toLowerCase(), 'signup_email');
      brazeUser.addAlias(externalId, 'external_id');
      brazeUser.setEmail(email.toLowerCase());
      brazeUser.setCustomUserAttribute(FIRST_SIGNUP_DATE, new Date());
    }

    brazeUser.addToCustomAttributeArray(BRAZE_SIGNUP_SOURCE, signUpSource);
    logCustomEvent(SIGNUP);

    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(config, brazeUser, true);
    }

    requestImmediateDataFlush();
    return externalId;
  } catch (error) {
    throw new Error(`Braze create new user failed: ${error.message}`);
  }
}

/**
 * Creates a Braze blog user with the provided configuration and attributes.
 * @param {object} brazeConfig - The Braze configuration object.
 * @param {string} externalId - The external ID of the user.
 * @param {boolean} existingUser -  value when set to true, does not update initial values like first_sign_up_date
 * @param {boolean} updateSubscription -  value when set to true, updates user to SUBSCRIBED status
 * @param {object} brazeCustomUserAttributes - The custom attributes for the Braze user.
 * @returns {Promise<void>} - A promise that resolves when the user is created successfully.
 * @throws {Error} - If the configuration is missing or invalid, or if the external ID is missing.
 */
export async function createBrazeBlogUser(
  brazeConfig,
  externalId,
  existingUser,
  updateSubscription,
  brazeCustomUserAttributes = {},
) {
  try {
    if (brazeConfig?.BRAZE_SDK_URL == null || brazeConfig?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (externalId == null) {
      throw new Error('Invalid parameters - externalId missing');
    }

    const { blogSlug, blogCategory, blogTags, email } = brazeCustomUserAttributes;

    if (email == null) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (!existingUser) {
      brazeUser.addAlias(email.toLowerCase(), 'signup_email');
      brazeUser.addAlias(externalId, 'external_id');
      brazeUser.setEmail(email.toLowerCase());
      brazeUser.setCustomUserAttribute(FIRST_SIGNUP_DATE, new Date());
    }

    brazeUser.addToCustomAttributeArray(BRAZE_SIGNUP_SOURCE, BRAZE_BLOG);
    logCustomEvent(SIGNUP);

    brazeUser.addToCustomAttributeArray(BRAZE_BLOG_SLUGS, blogSlug);
    brazeUser.addToCustomAttributeArray(BRAZE_BLOG_CATEGORIES, blogCategory);
    blogTags.forEach((tag) => {
      brazeUser.addToCustomAttributeArray(BRAZE_BLOG_TAGS, tag);
    });

    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(brazeConfig, brazeUser, true);
    }

    requestImmediateDataFlush();
    return externalId;
  } catch (error) {
    throw new Error(`Braze update user attributes failed: ${error.message}`);
  }
}

/**
 * Creates a Braze user for the Benefits Quiz.
 *
 * @param {object} brazeConfig - The Braze configuration object.
 * @param {string} externalId - The external ID of the user.
 * @param {boolean} existingUser -  when set to true, does not update initial values
 * @param {boolean} updateSubscription -  value when set to true, updates user to SUBSCRIBED status
 * @param {object} brazeCustomUserAttributes - The custom user attributes for the Braze user.
 * @returns {Promise<string>} The external ID of the created Braze user.
 * @throws {Error} If the Braze configuration is missing or invalid, or if the external ID is missing.
 * @throws {Error} If there is an error updating the user attributes in Braze.
 */
export async function createBrazeBenefitsQuizUser(
  brazeConfig,
  externalId,
  existingUser,
  updateSubscription,
  brazeCustomUserAttributes = {},
) {
  try {
    if (brazeConfig?.BRAZE_SDK_URL == null || brazeConfig?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (externalId == null) {
      throw new Error('Invalid parameters - externalId missing');
    }

    const { answers = [], email } = brazeCustomUserAttributes;

    if (email == null) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (!existingUser) {
      brazeUser.addAlias(email.toLowerCase(), 'signup_email');
      brazeUser.addAlias(externalId, 'external_id');
      brazeUser.setEmail(email.toLowerCase());
      brazeUser.setCustomUserAttribute(FIRST_SIGNUP_DATE, new Date());
    }

    brazeUser.addToCustomAttributeArray(BRAZE_SIGNUP_SOURCE, BRAZE_BENEFITS_QUIZ);
    logCustomEvent(SIGNUP);

    answers.forEach((answer, index) => {
      brazeUser.setCustomUserAttribute(`${BRAZE_BENEFITS_ANSWERS}_${index + 1}`, answer.text);
    });

    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(brazeConfig, brazeUser, true);
    }
    requestImmediateDataFlush();
    return externalId;
  } catch (error) {
    throw new Error(`Braze update Benefits_Quiz user attributes failed: ${error.message}`);
  }
}

export async function createBrazeLandingPageUser(
  brazeConfig,
  externalId,
  existingUser,
  updateSubscription,
  brazeCustomUserAttributes = {},
) {
  try {
    if (brazeConfig?.BRAZE_SDK_URL == null || brazeConfig?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (externalId == null) {
      throw new Error('Invalid parameters - externalId missing');
    }

    const { pageSlug = '', email } = brazeCustomUserAttributes;

    if (email == null) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (!existingUser) {
      brazeUser.addAlias(email.toLowerCase(), 'signup_email');
      brazeUser.addAlias(externalId, 'external_id');
      brazeUser.setEmail(email.toLowerCase());
      brazeUser.setCustomUserAttribute(FIRST_SIGNUP_DATE, new Date());
    }

    brazeUser.addToCustomAttributeArray(BRAZE_SIGNUP_SOURCE, BRAZE_MARKETING_LANDING);
    logCustomEvent(SIGNUP);

    brazeUser.addToCustomAttributeArray(BRAZE_LANDING_PAGE_SLUGS, pageSlug);

    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(brazeConfig, brazeUser, true);
    }
    requestImmediateDataFlush();
    return externalId;
  } catch (error) {
    throw new Error(`Braze update Landing Page user attributes failed: ${error.message}`);
  }
}

export async function createBrazeCheckoutUser(
  config,
  externalId,
  existingUser,
  updateSubscription,
  email,
  firstName,
  lastName,
  marketingConsent,
) {
  try {
    if (config?.BRAZE_SDK_URL == null || config?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (email == null) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (!existingUser) {
      brazeUser.addAlias(email.toLowerCase(), 'signup_email');
      brazeUser.addAlias(externalId, 'external_id');
      brazeUser.setEmail(email.toLowerCase());
      brazeUser.setFirstName(firstName);
      brazeUser.setLastName(lastName);
    }

    if (marketingConsent) {
      if (!existingUser) {
        brazeUser.setCustomUserAttribute(FIRST_SIGNUP_DATE, new Date());
      }
      brazeUser.addToCustomAttributeArray(BRAZE_SIGNUP_SOURCE, BRAZE_CHECKOUT);
      logCustomEvent(SIGNUP);
    }

    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(config, brazeUser, marketingConsent);
    }

    requestImmediateDataFlush();
    return externalId;
  } catch (error) {
    throw new Error(`Braze create new user failed: ${error.message}`);
  }
}

export async function createBrazeUserAfterPurchase(
  config,
  marketingConsent,
  customer,
  updateSubscription,
  skuName,
) {
  try {
    if (config?.BRAZE_SDK_URL == null || config?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (
      customer?.email == null ||
      customer?.externalId == null ||
      customer?.firstName == null ||
      customer?.lastName == null ||
      customer?.subscriptionStatus == null ||
      customer?.defaultShippingAddress == null ||
      updateSubscription == null
    ) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(customer.externalId);
    const brazeUser = getUser();

    brazeUser.setEmail(customer.email.toLowerCase());
    brazeUser.setFirstName(customer.firstName);
    brazeUser.setLastName(customer.lastName);
    brazeUser.setHomeCity(customer.defaultShippingAddress?.city);
    brazeUser.setCountry(customer.defaultShippingAddress?.country);

    brazeUser.setCustomUserAttribute(SKU_NAME_KEY, skuName);
    brazeUser.setCustomUserAttribute(SUBSCRIPTION_ACTIVE_KEY, customer.subscriptionStatus === 'active');

    brazeUser.setLanguage('en');
    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(config, brazeUser, marketingConsent);
    }

    requestImmediateDataFlush();
    return customer?.externalId;
  } catch (error) {
    throw new Error(`Braze create new user after purchase failed: ${error.message}`);
  }
}

export async function createBrazeTopicUser(
  brazeConfig,
  externalId,
  existingUser,
  updateSubscription,
  brazeCustomUserAttributes = {},
) {
  try {
    if (brazeConfig?.BRAZE_SDK_URL == null || brazeConfig?.BRAZE_WEB_KEY == null) {
      throw new Error('Missing or invalid config');
    }

    if (externalId == null) {
      throw new Error('Invalid parameters - externalId missing');
    }

    const { topicSlug, email } = brazeCustomUserAttributes;

    if (email == null) {
      throw new Error('Invalid parameters');
    }

    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (!existingUser) {
      brazeUser.addAlias(email.toLowerCase(), 'signup_email');
      brazeUser.addAlias(externalId, 'external_id');
      brazeUser.setEmail(email.toLowerCase());
      brazeUser.setCustomUserAttribute(FIRST_SIGNUP_DATE, new Date());
    }

    brazeUser.addToCustomAttributeArray(BRAZE_SIGNUP_SOURCE, BRAZE_TOPIC);
    logCustomEvent(SIGNUP);

    brazeUser.addToCustomAttributeArray(BRAZE_TOPIC, topicSlug);

    if (updateSubscription) {
      brazeUser.setEmailNotificationSubscriptionType(SUBSCRIBED);
      await updateUserSubscription(brazeConfig, brazeUser, true);
    }
    requestImmediateDataFlush();
    return externalId;
  } catch (error) {
    throw new Error(`Braze update topic user attributes failed: ${error.message}`);
  }
}

/**
 * Creates a Braze custom attributes user based on the provided parameters.
 *
 * @param {Object} brazeConfig - The Braze configuration object.
 * @param {string} externalId - The external ID of the user.
 * @param {boolean} existingUser -  value when set to true, does not update initial values like first_sign_up_date
 * @param {boolean} updateSubscription -  value when set to true, updates user to SUBSCRIBED status
 * @param {Object} brazeCustomUserAttributes - The custom user attributes for Braze.
 * @returns {Promise} - A promise that resolves to the created Braze user.
 */
export async function createBrazeCustomAttributesUser(
  brazeConfig,
  externalId,
  existingUser,
  updateSubscription,
  brazeCustomUserAttributes = {},
) {
  switch (brazeCustomUserAttributes?.type) {
    case BRAZE_BLOG:
      return createBrazeBlogUser(
        brazeConfig,
        externalId,
        existingUser,
        updateSubscription,
        brazeCustomUserAttributes,
      );
    case BRAZE_TOPIC:
      return createBrazeTopicUser(
        brazeConfig,
        externalId,
        existingUser,
        updateSubscription,
        brazeCustomUserAttributes,
      );
    case BRAZE_BENEFITS_QUIZ:
      return createBrazeBenefitsQuizUser(
        brazeConfig,
        externalId,
        existingUser,
        updateSubscription,
        brazeCustomUserAttributes,
      );
    case BRAZE_MARKETING_LANDING:
      return createBrazeLandingPageUser(
        brazeConfig,
        externalId,
        existingUser,
        updateSubscription,
        brazeCustomUserAttributes,
      );
    default:
      throw new Error(`Invalid Braze custom attributes user type ${brazeCustomUserAttributes?.type}`);
  }
}
