'use client';

import { GeolocationModal } from 'ui';
import { getConsentPurposes, NO_PURCHASE, OTHER_COUNTRY } from 'onetrust/src';
import { GEOLOCATION_ALLOWED, X_GEO_LOCATION_STATUS } from 'utils';
import { getCookie } from 'cookies-next';
import { createBrazeUser } from 'braze-react';
import postConsent from '../../../utils/onetrust/postConsent';

export default function GeoModalCaller({ brazeConfig, modalContent, env }) {
  const geoLocationStatus = getCookie(X_GEO_LOCATION_STATUS) || GEOLOCATION_ALLOWED;
  const collectionPoint = geoLocationStatus === GEOLOCATION_ALLOWED ? NO_PURCHASE : OTHER_COUNTRY;
  const consentPurposes = getConsentPurposes(brazeConfig.LINGO_ENV, collectionPoint);

  const getBrazeUser = async (email) => {
    const brazeUser = await fetch(`/api/marketing/user?email=${encodeURIComponent(email)}`).then((res) =>
      res.json(),
    );

    return brazeUser;
  };

  return (
    <GeolocationModal
      content={modalContent}
      collectionPoint={collectionPoint}
      consentPurposes={consentPurposes}
      postConsent={postConsent}
      getBrazeUser={getBrazeUser}
      createBrazeUser={createBrazeUser}
      brazeConfig={brazeConfig}
      env={env}
      styles={{
        title: { variant: 'h2' },
        boldSubtitle: { variant: 'body' },
        subtitle: { variant: 'body' },
        agreement: { variant: 'bodySM' },
      }}
    />
  );
}
