export default async function updateUserSubscription(config, brazeUser, subscribed) {
  if (
    config?.BRAZE_SUBSCRIPTION_NEWS_CONTENT == null ||
    config?.BRAZE_SUBSCRIPTION_PRODUCT_UPDATES == null ||
    config?.BRAZE_SUBSCRIPTION_SPECIAL_OFFERS == null
  ) {
    throw new Error('Missing or invalid subscription groups');
  }

  if (subscribed) {
    brazeUser.addToSubscriptionGroup(config.BRAZE_SUBSCRIPTION_NEWS_CONTENT);
    brazeUser.addToSubscriptionGroup(config.BRAZE_SUBSCRIPTION_PRODUCT_UPDATES);
    brazeUser.addToSubscriptionGroup(config.BRAZE_SUBSCRIPTION_SPECIAL_OFFERS);
  } else {
    brazeUser.removeFromSubscriptionGroup(config.BRAZE_SUBSCRIPTION_NEWS_CONTENT);
    brazeUser.removeFromSubscriptionGroup(config.BRAZE_SUBSCRIPTION_PRODUCT_UPDATES);
    brazeUser.removeFromSubscriptionGroup(config.BRAZE_SUBSCRIPTION_SPECIAL_OFFERS);
  }
}
