'use client';

import { useState } from 'react';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import { getBrazeFlag } from 'harness-react';
import { BRAZE_NO_PURCHASE_MODAL, BRAZE_OUT_OF_MARKET_MODAL, OPTED_IN } from 'braze';
import { createBrazeCustomAttributesUser, createBrazeUser } from 'braze-react';
import { validEmail, GEOLOCATION_ALLOWED, GEOLOCATION_COMING_SOON, X_GEO_LOCATION_STATUS } from 'utils';
import { getConsentPurposes, NO_PURCHASE, OTHER_COUNTRY } from 'onetrust';
import { v4 as UUIDv4 } from 'uuid';
import { getCookie } from 'cookies-next';
import logger from 'logger';
import postConsent from '../../utils/onetrust/postConsent';
import SignupStatusMessage from './signupStatusMessage';

export default function TextfieldEntry({
  brazeConfig,
  emailSuccessMessage,
  emailErrorMessage,
  buttonText,
  brazeCustomUserAttributes,
  brazeSignupSource,
}) {
  const geoLocationStatus = getCookie(X_GEO_LOCATION_STATUS) || GEOLOCATION_ALLOWED;
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false); // postConsent error
  const [isSuccess, setIsSuccess] = useState(false); // postConsent success
  const collectionPoint = geoLocationStatus === GEOLOCATION_ALLOWED ? NO_PURCHASE : OTHER_COUNTRY;

  const brazeEnabled = getBrazeFlag();

  const onSignUp = async () => {
    setIsLoading(true);
    // Reset state
    setIsError(false);
    setIsSuccess(false);

    if (!validEmail(email)) {
      setIsError(true);
      setIsLoading(false);
      return;
    }

    if (brazeEnabled) {
      try {
        const consentPurposes = getConsentPurposes(brazeConfig.LINGO_ENV, collectionPoint);

        const brazeUser = await fetch(`/api/marketing/user?email=${encodeURIComponent(email)}`).then((res) =>
          res.json(),
        );
        const externalId = brazeUser?.externalId ?? UUIDv4();
        const existingUser = brazeUser?.externalId != null;
        const updateSubscription = brazeUser?.emailSubscriptionState !== OPTED_IN;
        if (Object.keys(brazeCustomUserAttributes).length) {
          await createBrazeCustomAttributesUser(brazeConfig, externalId, existingUser, updateSubscription, {
            ...brazeCustomUserAttributes,
            email,
          });
        } else {
          const signUpSource =
            brazeSignupSource ||
            (geoLocationStatus === GEOLOCATION_COMING_SOON
              ? BRAZE_OUT_OF_MARKET_MODAL
              : BRAZE_NO_PURCHASE_MODAL);
          await createBrazeUser(
            brazeConfig,
            externalId,
            existingUser,
            updateSubscription,
            email,
            signUpSource,
          );
        }
        await postConsent(collectionPoint, consentPurposes, externalId);
        setIsSuccess(true);
        setIsLoading(false);
      } catch (error) {
        logger.error(error);
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className='relative'>
        <input
          className='signup-email-input relative z-10 w-full h-10 px-4 rounded'
          type='email'
          name='Email'
          onChange={(e) => {
            setEmail(e.target.value);
            setIsError(false);
          }}
          placeholder='Email address'
        />
        <button
          type='submit'
          disabled={isSuccess}
          className='button-dark disabled:bg-gray-100 disabled:text-gray-600 m-0 h-8 py-2 px-4 rounded absolute top-1 right-1 z-20 transition-all duration-700'
          data-analytics-location={LOCATION_PATHS.signup}
          data-analytics-action={buttonText}
          data-testid='signup-button'
          onClick={onSignUp}
        >
          {isLoading ? <span className='mx-4'>...</span> : buttonText}
        </button>
      </div>
      <SignupStatusMessage
        successMessage={emailSuccessMessage}
        errorMessage={emailErrorMessage}
        isSuccess={isSuccess}
        isError={isError}
      />
    </>
  );
}
